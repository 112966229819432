import { showMessage } from '../utils/showToast'
import { TOAST_TIPO } from '../global/toast'
import { getStorage } from '../utils/storage'
import { KEY_USER_LOCAL_STORAGE } from "../global/keys";

const APP_KEY = 'GM22A';

export default class ApiService {

    static async doRequest(url, options, onBefore, onSuccess, onError, onFinally, dispatch) {

        try {
            onBefore && onBefore();
            let response = await fetch(url, options);
            let resJson = await response.json();
            if (response.ok) {
                onSuccess(resJson);
            } else {
                if (onError) {
                    onError && onError(resJson);
                    return
                } else {
                    showMessage('Erro ao fazer requisição', TOAST_TIPO.WARNING)
                }
            }
        } catch (e) {
            if (onError) {
                onError && onError(e);
            } else {
                showMessage('Ops, algo deu errado. Verifique sua conexão de internet', TOAST_TIPO.ERROR)
            }
        } finally {
            onFinally && onFinally();
        }

    }

    static getParams(obj) {
        let formBody = [];
        for (let property in obj) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(obj[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        return formBody;
    }

    static async getOptions(method, body, token, formData, image) {

        let options = {}
        const methodFormatted = method.toUpperCase()
        options[`method`] = methodFormatted;

        if (formData) {
            let form = this.getParams(body)
            options[`body`] = form
        } else
            if (image) {
                let form = new FormData();
                for (let b in body) {
                    form.append(b, body[`${b}`])
                }

                options[`body`] = form
            } else {
                if (body) {
                    options[`body`] = JSON.stringify(body)
                }
            }

        let headers = {}
        if (formData) {
            headers = {
                'Content-type': 'application/x-www-form-urlencoded',
                'AppKey': APP_KEY,
            };
        } else if (image) {
            headers = {
                'AppKey': APP_KEY,
            }
        } else {
            headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Appkey: APP_KEY,
            }
        }

        if (token) {
            headers[`TokenUser`] = await this.getToken();
        } else {
            // headers[`TokenApp`] = `77c9ef64c2e3033a873aba28a05ab4036ca578810602020f293abcd4ea0791e5`
        }

        options[`headers`] = new Headers(headers)

        return options
    }

    static async getToken() {
        let user = await getStorage(KEY_USER_LOCAL_STORAGE)

        if (user) {
            return `${user.id}:${user.token}:${APP_KEY}`
        } else {
            return ''
        }
    }

    static getStatus() {
        let status = ["1", "4", "5", "6", "8"]
        let statusMap = {};
        status.forEach((s, k) => {
            statusMap[`status[${k}]`] = s;
        })
        return statusMap
    }


}