import styled from 'styled-components';

export const Container = styled.div`
    background-color: #ffffff;
    padding: 10px;
    width: 100%;
    min-height: 80%;
    border: ${`1px solid #dedede99`} ;
`;

export const RowContainer = styled.div`
    padding: 10px;
`