import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap'
import { Container, FormLogin, Title, ViewImage } from './styles'
import * as AuthServices from '../../services/AuthService'
import useLocalStorage from '../../hooks/useLocalStorage'
import { KEY_USER_LOCAL_STORAGE } from '../../global/keys';
import { changeLogin } from '../../reducers/autenticacao';

const Login = () => {

    const { login, errorsForm, } = useSelector(state => state.autenticacaoReducer)

    let navigate = useNavigate();

    const { set } = useLocalStorage()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(changeLogin())
    }, [])

    const entrar = (event) => {
        event.preventDefault()
        let obj = {
            email: login.email,
            senha: login.senha,
        }

        const onSuccess = (user) => {
            set(KEY_USER_LOCAL_STORAGE, user)
            navigate('/gestao')
        }

        dispatch(AuthServices.login(obj, onSuccess))
    }

    const isInvalid = (value) => {
        return errorsForm[`${value}`]
    }

    return (
        <Container>
            <FormLogin>
                <Form onSubmit={entrar} >
                    {/* <ViewImage>
                        <img src={require('../../assets/imgs/rede_ponte.jpg')} style={{ width: 200 }} />
                    </ViewImage> */}
                    <Title>Acessar MobiEditor</Title>
                    <FormGroup>
                        <Label for="email">
                            Email
                        </Label>
                        <Input
                            id="email"
                            placeholder="Digite seu e-mail"
                            type="email"
                            value={login.email}
                            onChange={event => dispatch(changeLogin({ email: event.target.value }))}
                            invalid={isInvalid('email')}
                        />
                        <FormFeedback style={{ marginBottom: -10 }} >
                            {errorsForm['email']}
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">
                            Senha
                        </Label>
                        <Input
                            id="password"
                            placeholder="Digite sua senha"
                            type="password"
                            value={login.senha}
                            onChange={event => dispatch(changeLogin({ senha: event.target.value }))}
                            invalid={isInvalid('senha')}
                        />
                        <FormFeedback style={{ marginBottom: -10 }} >
                            {errorsForm['senha']}
                        </FormFeedback>
                    </FormGroup>
                    <Button color='primary' block={true} disabled={login.loading} style={{ marginTop: 30 }} type='submit'>
                        {login.loading ? `Acessando...` : `Acessar`}
                    </Button>
                </Form>
            </FormLogin>

        </Container>
    )
}

export default Login;