import { TOAST_TIPO } from "../global/toast";
import { changeErrosLogin, changeLogin } from "../reducers/autenticacao";
import { showMessage } from "../utils/showToast";
import { validationLogin } from "../validation/login";
import ApiService from './ApiService'
import { LOGIN_WS } from "./webservices";

export const login = (body, onCallback) => {
    return async dispatch => {
        let validation = validationLogin(body);
        if (validation) {
            dispatch(changeErrosLogin(validation));
            showMessage('Existem erros no formulário, revise para efetuar o login', TOAST_TIPO.WARNING)
            return
        } else {
            dispatch(changeErrosLogin({}));
        }

        let options = await ApiService.getOptions('post', body, false, true)

        const onBefore = () => dispatch(changeLogin({ loading: true }))
        const onFinally = () => dispatch(changeLogin({ loading: false }))

        const onSuccess = (response) => {
            if (response) {
                if (response.perfil == 6) {
                    onCallback(response)
                } else {
                    showMessage('Acesso não autorizado', TOAST_TIPO.ERROR)
                }
            } else {
                showMessage('Erro ao realizar login', TOAST_TIPO.ERROR)
            }

        }
        const onError = (res) => {
            showMessage(res.message, TOAST_TIPO.ERROR)
        }

        ApiService.doRequest(LOGIN_WS, options, onBefore, onSuccess, onError, onFinally, dispatch)
    }
}
