import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ListBancoItens from "../views/ListBancoItens";
import Login from "../views/Login";
import PrivateRoute from './PrivateRoute'

function App() {
    return (
        <Routes>
            <Route
                exact
                path="/"
                element={
                    <Login />
                } />
            <Route
                exact
                path="/gestao"
                element={
                    <PrivateRoute>
                        <ListBancoItens />
                    </PrivateRoute>
                } />
            <Route path='*' element={<Navigate to={'/'} />} />
        </Routes>
    );
}

export default App