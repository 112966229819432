const light = {
    colorWhite: '#ffffff',
    colorPrimary: '#00E0C6',
    colorSecundary: '#2170ab',
    colorTerciary: '#016170',
    backgroundColorDefault: '#f7f9fc',
    backgroundColorDark: '#2170ab',
};

export const themes = {
    light,
};
