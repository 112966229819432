import React, { useEffect, useState } from 'react';
import { FaBars, FaUser } from 'react-icons/fa'
import { MdLogout } from 'react-icons/md'
import {
    Container,
    Menu,
    Header,
    MenuHeader,
    Body,
    Title,
    Link,
    MenuDrawer,
    BodyDrawer,
    ViewIconMenuHeader,
    TextMenuHeader,
    MenuBody,
    ItemMenuBody,
    TextItemMenuBody,
    Row
} from './styles';
import useLocalStorage from '../../hooks/useLocalStorage'
import { KEY_USER_LOCAL_STORAGE } from '../../global/keys';
import { useNavigate } from 'react-router-dom';

const App = ({ children }) => {

    const [isOpen, setIsOpen] = useState(false)
    const [user, setUser] = useState()

    const { get, remove } = useLocalStorage()

    const navigate = useNavigate()

    useEffect(() => {
        setUser(get(KEY_USER_LOCAL_STORAGE))
    }, [])


    const logout = (e) => {
        e.preventDefault()
        remove(KEY_USER_LOCAL_STORAGE)
        navigate('/', { replace: true })
    }

    const isActive = (url) => window.location.pathname.includes(url)

    // const renderMenuContent = () => {
    //     return (
    //         <>
    //             <MenuHeader>
    //                 <ViewIconMenuHeader>
    //                     <FaUser size={25} />
    //                 </ViewIconMenuHeader>
    //                 <TextMenuHeader>{user?.nome}</TextMenuHeader>
    //             </MenuHeader>
    //             <MenuBody>
    //                 <ItemMenuBody href='/gestao/dash' isActive={isActive('/dash')} >
    //                     <MdDashboard size={20} />
    //                     <TextItemMenuBody>Dashboard</TextItemMenuBody>
    //                 </ItemMenuBody>
    //                 <ItemMenuBody href='/gestao/itd' isActive={isActive('/itd')} >
    //                     <MdTrendingUp size={20} />
    //                     <TextItemMenuBody>Ind. trabalho digno</TextItemMenuBody>
    //                 </ItemMenuBody>
    //                 <ItemMenuBody href='/gestao/empresas' isActive={isActive('/empresas')} >
    //                     <MdAddBusiness size={20} />
    //                     <TextItemMenuBody>Cadastro de empresa</TextItemMenuBody>
    //                 </ItemMenuBody>
    //                 <ItemMenuBody href='#' onClick={logout}  >
    //                     <MdLogout size={20} />
    //                     <TextItemMenuBody>Sair</TextItemMenuBody>
    //                 </ItemMenuBody>
    //             </MenuBody>
    //         </>
    //     )
    // }

    return (
        <Container>
            {/* <Menu>
                {renderMenuContent()}
            </Menu>
            <MenuDrawer isOpen={isOpen} >
                {renderMenuContent()}
            </MenuDrawer> */}
            <Body>
                {/* {isOpen && <BodyDrawer onClick={() => setIsOpen(false)} />} */}
                <Header>
                    <Title>MobiEditor</Title>
                    <Link onClick={logout} >
                        {user?.nome}
                        <MdLogout style={{ marginLeft: 10 }} />
                    </Link>
                </Header>
                <Row>
                    {children}
                </Row>
            </Body>
        </Container>
    )
}

export default App;