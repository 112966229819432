const useLocalStorage = () => {
    function set(key, value) {
        const now = new Date()
        const item = {
            value: value,
            expiry: now.getTime() + 7200000,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    function get(key) {
        const itemStr = localStorage.getItem(key)
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        // if (now.getTime() > item.expiry) {
        //     // If the item is expired, delete the item from storage
        //     // and return null
        //     localStorage.removeItem(key)
        //     return null
        // }
        return item.value
    }

    function remove(key) {
        localStorage.removeItem(key)
    }

    return {
        set, get, remove
    }
}

export default useLocalStorage