import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import FilerobotImageEditor, {
    TABS,
    TOOLS,
} from 'react-filerobot-image-editor';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as BancoItensService from '../../../services/BancoItensService'

function ModalItem({ onClose, item, tab }) {

    const [questao, setQuestao] = useState()
    const [referencia, setReferencia] = useState(false)
    const dispatch = useDispatch()

    const { loadignSalvaItem } = useSelector(state => state.bancoItensReducer)

    const imageRef = useRef()

    useEffect(() => {
        setQuestao(item)
        if (!item) {
            setReferencia(false)
        }
    }, [item])

    const salvaImagem = (img) => {
        dispatch(BancoItensService.salvaItens({ img, com_referencia: referencia ? 1 : 0, id: questao.id }, onClose))
    }

    return (
        <Modal
            size="lg"
            isOpen={!!questao}
            toggle={!loadignSalvaItem ? onClose : () => null}
            centered={true}
            fullscreen={tab == 'editados' ? false : true}
            fade={true}>
            <ModalHeader toggle={!loadignSalvaItem ? onClose : () => null}>
                {tab == 'editados' ?
                    `Avaliação ${item?.av_id_temp} - Questão ${item?.questao_temp}` :
                    `Altere a imagem`}
            </ModalHeader>
            {tab == 'editados' ?
                <ModalBody>
                    <img style={{ width: '100%' }} src={item?.imagem_nova} />
                </ModalBody>
                :
                <ModalBody>
                    {!!questao &&
                        <div style={{ height: '100%' }} >
                            <div style={{ position: 'absolute', top: 27, left: 25, zIndex: 100, width: 70, backgroundColor: '#fff', height: 50 }} />
                            <div style={{ position: 'absolute', top: 27, right: 10, zIndex: 100, display: 'flex', alignItems: 'center' }} >
                                <div>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        onClick={() => setReferencia(!referencia)}
                                    />
                                    {' '}
                                    <Label check={referencia} for='checkbox2' style={{ fontSize: 14, marginBottom: -10 }}>
                                        Faz referência a outra questão?
                                    </Label>
                                </div>
                                <Button
                                    onClick={async () => {
                                        const { current: saveFunction } = imageRef;
                                        if (!saveFunction) return;
                                        const result = await saveFunction({ quality: 100 });
                                        let { imageData: editedImageObject } = result
                                        const base64Response = await fetch(editedImageObject.imageBase64);
                                        const blob = await base64Response.blob();
                                        const file = new File([blob], `${editedImageObject.name}.${editedImageObject.extension}`, { type: editedImageObject.mimeType });
                                        salvaImagem(file)
                                    }}
                                    size='sm'
                                    color='primary'
                                    style={{ fontSize: 14, height: 30, marginLeft: 15, marginRight: 10 }}
                                    disabled={loadignSalvaItem} >
                                    {loadignSalvaItem ? 'Salvando...' : 'Salvar'}
                                </Button>
                            </div>
                            <FilerobotImageEditor
                                getCurrentImgDataFnRef={imageRef}
                                source={questao?.imagem}
                                onSave={async (editedImageObject, designState) => {
                                    // console.log('saved', editedImageObject, designState)
                                    const base64Response = await fetch(editedImageObject.imageBase64);
                                    const blob = await base64Response.blob();
                                    const file = new File([blob], `${editedImageObject.name}.${editedImageObject.extension}`, { type: editedImageObject.mimeType });
                                    salvaImagem(file)
                                    // let a = document.createElement('a');
                                    // a.href = editedImageObject.imageBase64;
                                    // a.download = editedImageObject.fullName;
                                    // a.click();
                                }
                                }
                                // onClose={closeImgEditor}
                                annotationsCommon={{
                                    fill: '#ffffff',
                                }}
                                Text={{ text: 'Filerobot...' }}
                                Rotate={{ angle: 90, componentType: 'slider' }}
                                tabsIds={[TABS.ANNOTATE, TABS.ADJUST]} // or {['Adjust', 'Annotate', 'Watermark']}
                                defaultTabId={TABS.ANNOTATE} // or 'Annotate'
                                defaultToolId={TOOLS.RECT} // or 'Text'
                                language={'pt'}
                                closeAfterSave={true}
                            />
                        </div>
                    }
                </ModalBody>
            }
        </Modal>
    );
}

export default ModalItem;