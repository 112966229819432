import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: ${({ theme }) => theme.backgroundColorDark};
`

export const FormLogin = styled.div`
    padding: 20px 20px 30px;
    width: 500px;
    background-color: #fff;
    box-shadow:rgb(140 152 164 / 25%) 0px 3px 3px 0px;

    @media screen and (min-width: 320px) {
        width: 90%;
    }

    @media screen and (min-width: 576px) {
        width: 500px;
    }
`

export const Title = styled.h3`
    text-align: center;
    margin-bottom: 30px;
    font-weight: 400;
 
`

export const ViewImage = styled.div`
    text-align: center;
    margin: 0px 0px 0px 0px;
`