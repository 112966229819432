import { TOAST_TIPO } from '../global/toast'
import { changeItensEditados, changeItensParaEditar, changeLoadingItens, changeLoadingSalvaItem, changeTotal } from '../reducers/bancoItens'
import { showMessage } from '../utils/showToast'
import ApiService from './ApiService'
import { BANCO_ITENS_WS, COUNT_BANCO_ITENS_WS, SAlVA_IMAGE_WS } from './webservices'
import { get } from '@reduxjs/toolkit'

export const buscaItens = (body, callback) => {
    return async dispatch => {
        let options = await ApiService.getOptions('post', body, true, true)

        const onBefore = () => dispatch(changeLoadingItens(true))
        const onFinally = () => dispatch(changeLoadingItens(false))

        const onSuccess = (response) => {
            if (body.imagem_nova == 1) {
                dispatch(changeItensEditados({ list: response, pg: body.pg }))
            } else {
                dispatch(changeItensParaEditar({ list: response, pg: body.pg }))
            }
            callback && callback()
        }
        const onError = (res) => {
            showMessage(res.message, TOAST_TIPO.ERROR)
        }

        ApiService.doRequest(BANCO_ITENS_WS, options, onBefore, onSuccess, onError, onFinally, dispatch)
    }
}

export const salvaItens = (body, _callback) => {
    return async dispatch => {
        let options = await ApiService.getOptions('post', body, true, false, true)

        const onBefore = () => dispatch(changeLoadingSalvaItem(true))
        const onFinally = () => dispatch(changeLoadingSalvaItem(false))

        const onSuccess = (response) => {
            if (response) {
                const callback = () => {
                    dispatch(buscaItens({ imagem_nova: 1, pg: 1 }, _callback))
                }
                dispatch(buscaItens({ imagem_nova: 0, pg: 1 }, callback))
                dispatch(buscaQuantTotal({ imagem_nova: 0 }))
                dispatch(buscaQuantTotal({ imagem_nova: 1 }))
            }
        }

        const onError = (res) => {
            showMessage(res.message, TOAST_TIPO.ERROR)
        }
        ApiService.doRequest(SAlVA_IMAGE_WS, options, onBefore, onSuccess, onError, onFinally, dispatch)
    }
}

export const buscaQuantTotal = (body) => {
    return async dispatch => {
        let options = await ApiService.getOptions('post', body ? body : {}, true, true)

        const onBefore = () => null
        const onFinally = () => null

        const onSuccess = (response) => {
            dispatch(changeTotal(
                {
                    total: response.total,
                    tipo: body?.imagem_nova
                }
            ))
        }

        const onError = (res) => {
            showMessage(res.message, TOAST_TIPO.ERROR)
        }

        ApiService.doRequest(COUNT_BANCO_ITENS_WS, options, onBefore, onSuccess, onError, onFinally, dispatch)
    }
}

