import React from 'react';
import { Row } from 'reactstrap';
import { Container, RowTitle, Title } from './styles';

const ContainerApp = ({ children, title, button, transparent }) => {
    return (
        <Container
            transparent={transparent} >
            <Row>
                <RowTitle>
                    <Title>{title}</Title>
                    {button && button()}
                </RowTitle>
            </Row>
            {children}
        </Container>
    )
}

export default ContainerApp;