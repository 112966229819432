
import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    itensParaEditar: [],
    loadingList: false,
    itensEditados: [],
    loadignSalvaItem: false,

    countTotal: 0,
    countTotalParaEditar: 0,
    countTotalEditado: 0,
    paginaAtualParaEditar: 1,
    paginaAtualEditados: 1,
}

const slice = createSlice({
    name: 'itensReducer',
    initialState: INITIAL_STATE,
    reducers: {
        changeItensParaEditar: (state, action) => {
            if (action.payload.pg == 1) {
                state.itensParaEditar = action.payload.list
            } else {
                state.itensParaEditar = [...state.itensParaEditar, ...action.payload.list]
            }
            state.paginaAtualParaEditar = action.payload.pg + 1
        },
        changeLoadingItens: (state, action) => {
            state.loadingList = action.payload
        },
        changeItensEditados: (state, action) => {
            if (action.payload.pg == 1) {
                state.itensEditados = action.payload.list
            } else {
                state.itensEditados = [...state.itensEditados, ...action.payload.list]
            }
            state.paginaAtualEditados = action.payload.pg + 1
        },
        changeLoadingSalvaItem: (state, action) => {
            state.loadignSalvaItem = action.payload
        },
        changeTotal: (state, action) => {
            if (action.payload.tipo === 0) {
                state.countTotalParaEditar = action.payload.total

            } else if (action.payload.tipo === 1) {
                state.countTotalEditado = action.payload.total
            } else {
                state.countTotal = action.payload.total
            }
        },
    },
})

export const {
    changeItensEditados,
    changeItensParaEditar,
    changeLoadingItens,
    changeLoadingSalvaItem,
    changeTotal,
    changePaginaAtualParaEditar,
    changePaginaAtualEditados
} = slice.actions
export default slice.reducer


