
import React, { useEffect, useRef, useState } from 'react';
import { CardBody, Col, Card, CardTitle, CardSubtitle } from 'reactstrap';


const CardItem = ({ item, setItemSelecionado, tab }) => {

    const [questao, setQuestao] = useState(item)

    useEffect(() => {
        setQuestao(item)
    }, [item])

    return (
        <Col md='3' sm='12' style={{ marginBottom: 10 }} >
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer'
                }}
                onClick={() => setItemSelecionado(questao)}>
                <CardBody>
                    <CardTitle tag="h5">
                        Avaliação {item.av_id_temp}
                    </CardTitle>
                    <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6" >
                        Questão {item.questao_temp}
                    </CardSubtitle>
                </CardBody>
                <img
                    alt="Card cap"
                    src={tab == 'editados' ? item.imagem_nova : item.imagem}
                    width="100%"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        let img = tab == 'editados' ? item.imagem_nova : item.imagem
                        let ext = img.substring(img.length - 3, img.length)
                        let url = img.substring(0, img.length - 3)
                        let src = `${url}${ext == 'jpg' ? 'png' : 'jpg'}`;

                        currentTarget.src = src

                        setQuestao({ ...item, imagem: src })
                    }}
                />
            </Card>
        </Col>
    )
}

export default CardItem;