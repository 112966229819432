export const validationLogin = (body) => {
    let erros = {}

    if (!body.email) {
        erros[`email`] = 'E-mail obrigatório'
    }

    if (!body.senha) {
        erros[`senha`] = 'Senha obrigatória'
    }

    return Object.keys(erros).length > 0 ? erros : null
}