import { toast } from "react-toastify"
import { TOAST_TIPO } from "../global/toast"

export const showMessage = (message, type) => {

    let { error, warn, info, success } = toast

    let func =
        type == TOAST_TIPO.ERROR ?
            error :
            type == TOAST_TIPO.WARNING ?
                warn :
                type == TOAST_TIPO.SUCCESS ?
                    success :
                    type == TOAST_TIPO.INFO ?
                        info :
                        null

    if (func) {
        func(message)
    }
}

export const getStatusMessage = (status) => {
    switch (status) {
        case 'ERROR':
            return TOAST_TIPO.ERROR
        default:
            return status ? status.toLowerCase() : 'warning'

    }
}