import React, { useEffect, useRef, useState } from 'react';
import ContainerApp from '../../components/ContainerApp';
import { Container, RowContainer } from './styles'
import {
    Nav,
    NavItem,
    NavLink,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardLink,
    CardText,
    Row,
    Col,
    Button
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { buscaItens, buscaQuantTotal } from '../../services/BancoItensService';
import Modal from './Modal';
import CardItem from './Card';

const ListBancoItens = () => {

    const [tab, setTab] = useState('para_edicao')
    const [list, setList] = useState([])
    const [itemSelecionado, setItemSelecionado] = useState()
    const [visible, setVisible] = useState(false)

    const {
        itensParaEditar,
        loadingList,
        itensEditados,
        countTotal,
        countTotalParaEditar,
        countTotalEditado,
        paginaAtualParaEditar,
        paginaAtualEditados,


    } = useSelector(state => state.bancoItensReducer)


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(buscaItens({ imagem_nova: 0, pg: paginaAtualParaEditar }))
        dispatch(buscaItens({ imagem_nova: 1, pg: paginaAtualEditados }))
        dispatch(buscaQuantTotal({}))
        dispatch(buscaQuantTotal({ imagem_nova: 1 }))
        dispatch(buscaQuantTotal({ imagem_nova: 0 }))
    }, [])

    useEffect(() => {
        if (tab == 'para_edicao') {
            setList(itensParaEditar)
        } else {
            setList(itensEditados)
        }
    }, [itensParaEditar, itensEditados, tab])

    const renderCard = (item, key) => {
        return (
            <CardItem
                item={item}
                key={key}
                setItemSelecionado={(item) => setItemSelecionado(item)}
                tab={tab}
            />
        )
    }

    const load = () => {
        if (tab == 'para_edicao') {
            dispatch(buscaItens({ imagem_nova: 0, pg: paginaAtualParaEditar }))
        } else {
            dispatch(buscaItens({ imagem_nova: 1, pg: paginaAtualEditados }))
        }
    }

    return (
        <>
            <ContainerApp
                title={'Banco de itens'}
                button={() => <p>Quant. total: {countTotal}</p>}>
                <Nav style={{ marginTop: 10 }} tabs>
                    <NavItem>
                        <NavLink onClick={() => setTab('para_edicao')} href="#" active={tab == 'para_edicao'}>
                            PARA EDIÇÃO ({countTotalParaEditar})
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setTab('editados')} href="#" active={tab == 'editados'}>
                            EDITADOS ({countTotalEditado})
                        </NavLink>
                    </NavItem>
                </Nav>
                <RowContainer>
                    <Row>
                        {
                            list.map((item, key) => {
                                return renderCard(item, key)
                            })
                        }
                        {
                            !loadingList && list.length == 0 &&
                            <p>Nenhum item encontrado</p>
                        }
                    </Row>
                    {list.length >= 50 && list.length < countTotal ?
                        <div style={{ display: 'flex', justifyContent: 'center' }} >
                            <Button onClick={() => load()} color="primary" disabled={loadingList}  >
                                {loadingList ? 'Carregando...' : 'Carregar mais'}
                            </Button>
                        </div>
                        : null}
                </RowContainer>
            </ContainerApp>
            <Modal
                onClose={() => setItemSelecionado(undefined)}
                item={itemSelecionado}
                tab={tab}
            />
        </>
    )
}

export default ListBancoItens;