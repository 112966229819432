import styled from 'styled-components';

export const Container = styled.div`
    background-color: ${({ transparent, theme }) => transparent ? theme.backgroundColorDefault : '#fff'};
    padding: 10px;
    width: 100%;
    min-height: 80%;
    border: ${({ transparent }) => transparent ? `0px` : `1px solid #dedede99`} ;
`;

export const RowTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (min-width: 320px) {
        flex-direction: column;
        align-items: flex-start;
    } 

    @media screen and (min-width: 576px) {
        flex-direction: row;
        align-items: center;
    } 
`

export const Title = styled.p`
    font-size: 25px;
    font-weight: 500;
    margin: 0px;
`