import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import AutenticacaoReducer from './autenticacao'
import BancoItensReducer from './bancoItens'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: []
}

const reducers = combineReducers({
    autenticacaoReducer: AutenticacaoReducer,
    bancoItensReducer: BancoItensReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
});

const persistor = persistStore(store);

export { store, persistor }