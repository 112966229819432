import styled, { css } from "styled-components";

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: ${({ theme }) => theme.backgroundColorDefault};
    display: flex;
`

export const MenuDrawer = styled.div`
    position: fixed;
    left: 0;
    height: 100%;
    overflow-y: auto;
    width: 250px;
    background-color: #36474f;
    z-index: 200;
    
   ${(props) => props.isOpen ?
        css`
            transition: all 0.3s ease-out;
            left: 0px;
   ` :
        css`
        transition: all 0.3s ease-out;
        left: -250px;
   `}

   @media screen and (min-width: 576px) {
        transition: all 0.3s ease-out;
        left: -250px;
    } 
`

export const Menu = styled.div`
    height: 100%;
    overflow-y: auto;
    min-width: 250px;
    width: 250px; 
    background-color: #36474f;

    @media screen and (min-width: 320px) {
        display: none;
    } 
     
    @media screen and (min-width: 576px) {
        display: block;
    } 
`

export const MenuHeader = styled.div`
    height: 150px;
    width: 100%;
    background-color: #253237;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
`

export const ViewIconMenuHeader = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #36474f;
    margin-bottom: 10px;
`

export const TextMenuHeader = styled.div`
   font-size: 16px;
   color: #dedede99;
`

export const MenuBody = styled.div`
    padding:10px ;
`

export const ItemMenuBody = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    color: ${props => props.isActive ? `#dedede` : `#dedede99`};
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #dedede;
    }
`

export const TextItemMenuBody = styled.p`
   font-size: 14px;
   margin: 0px 0px 0px 15px;
`

export const Body = styled.div`
    height: 100%;
    width: calc(100% - 250px);

    @media screen and (min-width: 320px) {
        width: 100%;
    } 
`

export const BodyDrawer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4;
    pointer-events: auto;
    opacity: 1;
    background-color: rgba(0,0,0,.5);
    visibility: visible;
    transition-property: opacity;

    @media screen and (min-width: 320px) {
        display: block;
        
    }
    @media screen and (min-width: 576px) {
        display: none;
    } 
`

export const Header = styled.div`
    height: 64px;
    width: 100%;
    background-color: ${({ theme }) => theme.colorSecundary};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 0px 20px;
`

export const Title = styled.p`
    font-size: 25px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0px;
    margin-right: 20px;

    @media screen and (min-width: 320px) {
        margin: 0px 0px 0px 20px;
    } 
    
    @media screen and (min-width: 576px) {
        margin: 0px;
    } 
`

export const Link = styled.a`
    /* margin-le; */
    font-size: 18px;
    color: #fff;
    margin-right: 30px;
    text-decoration: none;
    cursor: pointer;

    &:hover{
        color: ${({ theme }) => theme.colorTerciary};
    }
/* 
    @media screen and (min-width: 320px) {
        display: block;
    }

    @media screen and (min-width: 576px) {
        display: none;
    } */
`

export const Row = styled.div`
    padding: 20px;
    width: 100%;
    height: 90%;
    overflow-y: auto;

    @media screen  and (max-width: 576px) {
        padding: 8px;
    }
`