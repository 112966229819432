import React from 'react'
import { Navigate } from 'react-router-dom'
import { KEY_USER_LOCAL_STORAGE } from '../global/keys'
import App from '../views/App'
import useLocalStorage from '../hooks/useLocalStorage'

const PrivateRoute = ({ children }) => {
    const { get } = useLocalStorage()

    if (!get(KEY_USER_LOCAL_STORAGE)) {
        return <Navigate to="/login" />;
    }

    return (
        <App>
            {children}
        </App>
    )
}


export default PrivateRoute
