
import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    login: {
        email: '',
        senha: '',
        loading: false
    },
    errorsForm: {}
}

const autenticacaoSlice = createSlice({
    name: 'loginReducer',
    initialState: INITIAL_STATE,
    reducers: {
        changeLogin: (state, action) => {
            if (action.payload) {
                state.login = { ...state.login, ...action.payload }
            } else {
                state.login = INITIAL_STATE.login
            }
        },
        changeErrosLogin: (state, action) => {
            state.errorsForm = action.payload
        }
    },
})

export const { changeLogin, changeErrosLogin } = autenticacaoSlice.actions
export default autenticacaoSlice.reducer


